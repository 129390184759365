<template>
  <section class="yml">
    <div class="f mb-20 w">
      <div>
        <h1>Bash Commands</h1>
        <p>Список полезных Bash Command</p>
      </div>
      <a class="btn" target="_blank" href="https://server.yablonev.art/services/bash">Открыть в виде JSON</a>
    </div>
    <div v-for="type of Object.keys(result)" :key="type">
      <h2 class="capitalize">{{ getText(type) }}</h2>
      <div v-for="(token, i) of result[type]" :key="i">
        <div class="action_result">
          <p>{{ token.description }}</p>
          <pre v-highlightjs="token.command"><code class="bash"></code></pre>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { API } from '@/logic/helper'

export default {
  name: 'BashCommands',
  data() {
    return {
      result: {}
    }
  },
  methods: {
    getText(type) {
      const t = {
        imac: 'IMac',
        imac_custom: 'Custom IMac',
        ubuntu: 'Ubuntu',
        hotkeys: 'Горячие клавиши',
      }

      return t[type] || type
    }
  },
  async mounted() {
    const result = await API('services/bash')
    this.result = result.data
  }
}
</script>


<style lang="sass" scoped>
@import '@/assets/sass/pages/nginx'

.action_result
  margin-top: 8px

  p
    color: #4dbf90
    font-size: 14px
    line-height: 28px
    padding-left: 0.3em

h2
  color: #475a75
  font-size: 22px
  font-weight: 500
  margin: 0

.capitalize
  text-transform: capitalize
</style>